<template>
  <div class="plant-select-page">
    <Header text="确定" @textClick="confirm" />
    <div class="search-wrap">
      <Search v-model="searchTmp" @search="searchTotal = searchTmp" />
    </div>
    <PlantSelect :list="pickerData" :value="value" />
    <infinite-loading ref="infinite" @infinite="infiniteHandler">
      <span slot="no-more">
        <!-- 暂无更多 -->
      </span>
    </infinite-loading>
  </div>
</template>

<script>
import Header from "@/components/header/headerWithText/index.vue";
import Search from "@/components/common/search/index.vue";
import PlantSelect from "./components/plantSelect.vue";
import { fetchPlantList } from "@/api/select";
import InfiniteLoading from "vue-infinite-loading";

export default {
  props: {
    value: {},
  },
  components: {
    Header,
    Search,
    PlantSelect,
    InfiniteLoading,
  },
  data() {
    return {
      list: [
        // {plant: '西芹', type: '类型1', cover: false},
      ],
      pageNo: 1,
      pageSize: 20,
      total: 0,
      searchTmp: "",
      searchTotal: "",
    };
  },
  computed: {
    pickerData() {
      if (this.searchTotal) {
        return this.list.filter((item) => {
          return (
            (item.plantName || "").includes(this.searchTotal) ||
            (item.plantType || "").includes(this.searchTotal)
          );
        });
      } else {
        return this.list;
      }
    },
  },
  methods: {
    async infiniteHandler($state) {
      try {
        await this.fetchPlant();
        $state.loaded();
        this.pageNo += 1;
        if (this.total - this.list.length === 0) {
          $state.complete();
        }
      } catch (e) {
        $state.loaded();
        $state.complete();
      }
    },
    async fetchPlant() {
      let data = {
        "page.pageNo": this.pageNo,
        "page.pageSize": this.pageSize,
      };
      let response = await fetchPlantList(data);
      this.list = this.list.concat(response.data || []);
      // this.total = (response.page && response.page.total) || 0;
      // 默认返回了全部
      this.total = (response.data || []).length;
    },
    confirm() {
      this.searchTmp = "";
      this.searchTotal = "";
      this.$emit("confirm");
    },
  },
};
</script>

<style lang="scss" scoped>
.plant-select-page {
  width: 100%;
  min-height: 100vh;
  background: #fafbfc;
  .search-wrap {
    width: 100%;
    height: 44px;
    box-sizing: border-box;
    background: white;
    padding: 10px 24px 10px 12px;
  }
}
</style>
