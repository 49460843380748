<template>
  <HeaderComponent class="header">
    <div class="text-wrap">
      <template v-if="(typeof text) === 'string'">
        <span @click="handleClick()">{{text}}</span>
      </template>
      <template v-else>
        <span style="margin-left: 10px;" v-for="(item, index) in text" :key="index" @click="handleClick(item.event)">{{item.text}}</span>
      </template>
    </div>
  </HeaderComponent>
</template>

<script>
import HeaderComponent from "@/components/header/index.vue"

export default {
  components: {
    HeaderComponent,
  },
  props: {
    text: {
      type: [String, Array],
    } 
  },
  data() {
    return {

    }
  },
  methods: {
    handleClick(item) {
      if (item) {
        this.$emit(item)
      }
      else {
        this.$emit('textClick')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  .text-wrap {
    width: 100%;
    height: 100%;
    padding-right: 24px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 14px;
    color: white;
  }
}
</style>