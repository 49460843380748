<template>
  <Container :visible="visible" class="select-map" @confirm="onConfirm">
    <Row class="break" label="竞品品牌" @click="isShowProductModal=true">
      <span class="label">{{prodcutList[0] && prodcutList[0].brandName || '请选择'}}</span>
    </Row>
    <Row label="种植作物" @click="isShowPlantModal=true">
      <span class="label">{{plantList[0] && plantList[0].plantName || '请选择'}}</span>
    </Row>
    <Row class="break" label="本年销量">
      <RangeInput :defaultValue="[0, 0]" unit="吨" v-model="salesVolume" />
    </Row>
    <Row label="销量同比">
      <RangeInput :defaultValue="[0, 0]" unit="%" v-model="salesCompare" />
    </Row>
    <Row label="容量">
      <RangeInput :defaultValue="[0, 0]" unit="吨" v-model="totalCapacity" />
    </Row>
    <div class="fixed-container" v-if="isShowProductModal || isShowPlantModal">
      <ProductionSelect
        selectType="brand"
        userType="ziyou"
        @confirm="isShowProductModal=false"
        v-model="prodcutList"
        v-if="isShowProductModal"
      />
      <PlantSelect
        @confirm="isShowPlantModal=false"
        v-model="plantList"
        v-if="isShowPlantModal"
      />
    </div>
  </Container>
</template>

<script>
import Row from "../row";
import Container from "../container";
import RangeInput from '@/components/common/rangeInput';
import PlantSelect from '@/views/select/plantSelect';
import ProductionSelect from '@/views/select/textSelect';

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Row,
    Container,
    RangeInput,
    PlantSelect,
    ProductionSelect,
  },
  data() {
    return {
      totalCapacity: [],
      salesVolume: [],
      salesCompare: [],
      prodcutList: [],
      plantList: [],
      isShowPlantModal: false,
      isShowProductModal: false,
    };
  },
  methods: {
    onConfirm() {
      this.$emit('confirm', {
        salesCompare1: this.salesCompare[0] || '',
        salesCompare2: this.salesCompare[1] || '',
        salesVolume1: this.salesVolume[0] || '',
        salesVolume2: this.salesVolume[1] || '',
        totalCapacity1: this.totalCapacity[0] || '',
        totalCapacity2: this.totalCapacity[1] || '',
        compBrand: this.prodcutList[0] && this.prodcutList[0].brandId || '',
        plant: this.plantList[0] && this.plantList[0].id || '',
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.select-map {
  .break {
    margin-top: 12px;
  }
  .label {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #cecece;
  }
}
</style>
