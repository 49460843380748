<template>
  <div class="text-select-item" :class="{active}">
    <div class="value" v-for="(item, index) in fields" :key="index" >{{info[item]}}</div>
  </div>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false
    },
    fields: {
      type: Array,
      default: ()=>{
        return []
      }
    },
    info: {
      type: Object,
      default: ()=>{
        return {}
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.text-select-item {
  width: 100%;
  height: 40px;
  border-bottom: 1px solid #fafbfc;
  background: #fafbfc;
  box-sizing: border-box;
  padding: 0 24px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #91939A;
  background: white;
  .value {
    margin-right: 12px;
  }
}
.active {
  color: white;
  background: #CD001F;
}
</style>