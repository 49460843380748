<template>
  <div class="owner-select-page">
    <Header text="确定" @textClick="$emit('confirm')" />
    <div class="search-container">
      <Search v-model="searchTotal" @search="resetList" />
    </div>
    <TextSelect :single="single" :init="value" :list="list" :activeField="activeField[selectType]" :fields="fields[selectType]"  @change="handleChange"></TextSelect>
    <!-- 无限加载 -->
    <infinite-loading ref="infinite" @infinite="infiniteHandler">
      <span slot="no-more">
        <!-- 暂无更多 -->
      </span>
    </infinite-loading>
  </div>
</template>

<script>
import TextSelect from './components/textSelectList.vue'
import Header from '@/components/header/headerWithText/index.vue'
import Search from '@/components/common/search/index.vue'
import InfiniteLoading from 'vue-infinite-loading'
import { fetchSDLPotentialList, fetchZYPotentialList, fetchDictList } from '@/api/select'

export default {
  components: {
    Header,
    Search,
    TextSelect,
    InfiniteLoading,
  },
  props: {
    value: {},
    // potential brand
    selectType: {
      type: String,
      default: 'default'
    },
    // params: shidanli   ziyou
    userType: {
      type: String,
      default: 'shidanli'
    },
    // multi single
    single: {
      type: Boolean,
      default: false
    },
    directReturn: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      activeField: {
        potential: 'id',
        brand: 'dictValue'
      },
      fields: {
        potential: ['contactPerson'],
        brand: ['dictValue']
      },
      list: [],
      pageNo: 1,
      pageSize: 10,
      total: 0,
    }
  },
  methods: {
    async infiniteHandler($state) {
      switch(this.selectType){
        case 'potential': {
          this.fetchPotentialList();
          break
        }
        case 'brand': {
          this.getBrandList()
          break
        }
      }
      $state.loaded();
      this.pageNo += 1;
      if (this.total - this.list.length === 0) {
        $state.complete();
      }
    },

    async fetchPotentialList() {
      let response = {}
      let data = {
        searchTotal: this.searchTotal || '',
        "page.pageNo": this.pageNo,
        "page.pageSize": this.pageSize,
        coordinate: '',
      }
      if (this.userType === 'shidanli') {
        response = await fetchSDLPotentialList(data)
      }
      else if(this.userType === 'ziyou') {
        response = await fetchZYPotentialList(data)
      }
      this.list = this.list.concat(response.data || [])
      this.total = (response.page && response.page.total) || 0 
    },

    async getBrandList() {
      let data = {
        type: 'crm_compete_brand'
      }
      let response = await fetchDictList(data)
      this.list = this.list.concat(response.data || [])
      this.total = this.list.length || 0 
    },

    handleChange(data) {
      this.$emit('input', data)
    },

    resetList() {
      this.$refs.infinite._events["$InfiniteLoading:reset"][0]();
      this.list = [];
      this.pageNo = 1;
      this.total = 0;
    },
  }
}
</script>

<style lang="scss" scoped>
.owner-select-page {
  width: 100%;
  min-height: 100vh;
  height: 100vh;
  overflow: auto;
  position: absolute;
  top: 0;
  left: 0;
  background: #FAFBFC;
  .search-container {
    width: 100%;
    height: 44px;
    box-sizing: border-box;
    padding: 10px 24px 10px 12px;
    background: white;
  }
  
}
</style>