<template>
  <div class="plant-select-wrap">
    <div class="type-container">
      <div class="cell">种植作物</div>
      <div class="cell">种植类型</div>
      <div class="cell">覆盖</div>
    </div>
    <div class="list-container">
      <div class="row" v-for="(item, index) in list" :key="index">
        <div class="cell">{{item.plantName}}</div>
        <div class="cell">{{item.plantType}}</div>
        <div class="cell">
          <img class="check-icon" :src="active[item.id] ? CheckIcon : NotCheckIcon" @click="handleClick(index, item.id)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CheckIcon from '@/assets/images/common/circle-check.png'
import NotCheckIcon from '@/assets/images/common/not-check.png'

export default {
  props: {
    list: {
      type: Array,
      default: ()=>{
        return []
      }
    },
    value: {
      type: Array,
      default: ()=>{
        return []
      }
    }
  },
  data() {
    return {
      CheckIcon,
      NotCheckIcon
    }
  },
  computed: {
    active() {
      let ret = []
      this.value.map(item=>{
        ret[item.id] = true
      })
      return ret
    }
  },
  methods: {
    handleClick(clickIndex, id) {
      let tempValue = this.value
      let valueIndex = -1
      tempValue.map((item, index)=>{
        if (item.id === id) {
          valueIndex = index
        }
      })
      if (valueIndex === -1) {
        tempValue.push(this.list[clickIndex])
        //作物覆盖搜索限制单选
        if (tempValue && tempValue.length > 1) {
          tempValue.splice(0, 1)
        }
      }
      else {
        tempValue.splice(valueIndex, 1)
      }
      this.$emit('input', tempValue)
    }
  }
}
</script>

<style lang="scss" scoped>
.plant-select-wrap {
  padding-top: 12px;
  font-size: 14px;
  .type-container {
    width: 100%;
    height: 44px;
    display: flex;
    align-items: center;
    color: #2C2C2C;
    background: white;
  }
  .cell {
    flex: 1;
  }
  .list-container {
    width: 100%;
    padding-top: 12px;
    .row {
      width: 100%;
      height: 44px;
      display: flex;
      align-items: center;
      color: #818181;
      background: white;
    }
    .check-icon {
      width: 17px;
      height: 17px;
    }
  }
}
</style>