import { render, staticRenderFns } from "./textSelectList.vue?vue&type=template&id=27a9d76e&scoped=true&"
import script from "./textSelectList.vue?vue&type=script&lang=js&"
export * from "./textSelectList.vue?vue&type=script&lang=js&"
import style0 from "./textSelectList.vue?vue&type=style&index=0&id=27a9d76e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27a9d76e",
  null
  
)

export default component.exports